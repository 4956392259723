export const PostMail = '/Authentication/PostMail'
export const CheckPassword = '/Authentication/CheckPassword'
export const RequestPassword = '/Authentication/RequestPassword'

export const GetCategorieDocumenti = '/Documenti/GetCategorieDocumenti'
export const GetDocumenti = '/Documenti/GetDocumenti'
export const UploadFiles = '/Documenti/UploadFiles'
export const DeleteFile = '/Documenti/DeleteFile'
export const InviaDocumenti = '/Documenti/InviaDocumenti'

export const GetPratiche = '/Pratica/GetPratiche'
export const GetPratica = '/Pratica/GetPratica'
export const GetReferente = '/Pratica/GetReferente'
export const GetPraticaTimeline = '/Pratica/GetPraticaTimeline'
export const GetDocumentoCode = '/Pratica/GetDocumentoCode'

export const GetFile = '/api/FileProvider/GetFile'
export const GetFilePreview = '/api/FileProvider/GetFilePreview'

export const TraceAccess = '/Trace/TraceAccess'