import * as React from 'react'
import { Collapse, Col, Row } from 'antd'
import moment from 'moment'
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel'

import { PraticaMutuoTimelineItemModel } from '../../model/PraticaModels'

import './TimelineCard.less'

import status_ok from '../../content/status_ok.svg'
import inoltrato from '../../content/inoltrato.svg'
import corrente from '../../content/md-hourglass.svg'
import { CheckCircleOutlined } from '@ant-design/icons'

const { Panel } = Collapse

interface TimelineCardProps {
    defaultExpandedNodes: Array<string>
    item: PraticaMutuoTimelineItemModel
}

export const TimelineCard: React.FC<TimelineCardProps> = (props) => {

    const renderIcon = (item: PraticaMutuoTimelineItemModel) => {
        if (item.StatoCorrente === true)
            return <img src={corrente} style={{ transform: 'rotate(-30deg)'}} height="16px" />
        else if (item.StatoPassato === true)
            return <CheckCircleOutlined style={{ color: "#F98885", fontSize: '18px' }}/>
        else
            return <></>
    }

    const renderDate = (item: PraticaMutuoTimelineItemModel) => {
        if (!item.Data) return
        return <span style={{ margin: '15px' }} className={item.StatoCorrente == true ? 'Semibold17VerdeScuro' : 'SemiBold16Arancio'}>{moment(item.Data).format('L')}</span>
    }

    const getTitleClass = (item: PraticaMutuoTimelineItemModel): string => {
        if (item.StatoCorrente == true) return 'Bold17VerdeScuro';
        if (item.StatoPassato == true) return 'Bold17Arancio'

        return 'Bold17VerdeScuroDisabled';
    }

    const renderHeader = (item: PraticaMutuoTimelineItemModel) => {
        return (
            <Row justify="space-between" align="bottom">
                <Col>
                    <span className={getTitleClass( item)} >{item.Titolo}</span>
                </Col>
                <Col>{renderDate(item)}</Col>
            </Row>
        )
    }

    const isExpanded = (item: PraticaMutuoTimelineItemModel): boolean => {
        return props.defaultExpandedNodes.findIndex(e => item.Codice == e) > -1
    }

    const getDefaultKeys = (item: PraticaMutuoTimelineItemModel) => {
        return isExpanded(item) ? ["1"] : []
    }

    const isCollapible = (item: PraticaMutuoTimelineItemModel): CollapsibleType => {
        return item.StatoPassato === true ? null : "disabled"
    }

    let style = {}
    if (props.item.StatoPassato !== true)
        style = { opacity: '0.6' }

    return (
        <Collapse ghost defaultActiveKey={getDefaultKeys(props.item)} expandIcon={_ => renderIcon(props.item)} expandIconPosition={'right'}
                  collapsible={isCollapible(props.item)}>
                <Panel key="1" header={renderHeader(props.item)} style={{ backgroundColor: 'white' }}>
                    <span className={props.item.StatoCorrente == true ? 'Semibold16VerdeScuro' : 'Semibold17Arancio'}>{props.item.Descrizione}</span>
                </Panel>
            </Collapse>
    )
}