import * as React from 'react'
import { Row, Col, Card, Form, Input, Button } from 'antd'

import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { INotifyService } from '../../services/shared/NotifyService'
import { IAuthenticationService } from '../../services/AuthenticationService'

import info_grey from '../../content/info_grey.svg'
import logo from  '../../content/AvveraLogo.svg';
import { AppContext } from '../AppContext'


class LoginState {
    mail: string
    password: string
}


class PageLoginCmp extends React.Component<any, LoginState> {

    private authenticationService: IAuthenticationService
    private notifyService: INotifyService

    constructor(props) {
        super(props)

        this.state = new LoginState();

        this.authenticationService = IoCContainer.get(SERVICE_IDENTIFIER.AUTHENTICATION_SERVICE);
        this.notifyService = IoCContainer.get(SERVICE_IDENTIFIER.NOTIFY);
    }

    onHelp = () => {
        throw new Error('Method not implemented.')
    }

    richiediPassword =async () => {
        let resp = await  this.authenticationService.RequestPassword(this.state.mail);
        if (resp.IsValid === true) {
            this.notifyService.success("La richiesta è stata inviata correttamente. Riceverai la nuova password entro pochi secondi tramite SMS");
        }
    }

    login = async () => {
        await this.authenticationService.Login(this.state.mail, this.state.password)
    }

    render() {
        return (
            <div className="login-bgimage" >
            <Row>
                <Col>
                        <Card className='card-top' >
                            <img src={logo}  />
                        </Card>
                </Col>
            </Row>
            <Row >
                <Col lg={{ offset: 8, span: 8 }} sm={{ offset: 6, span: 12 }} xs={{ offset: 2, span: 20 }}>
                        <Card style={{ marginTop: '24px' }}>
                        <div>
                            <Row>
                                <Col >
                                    <p className="Bold24Rosso">Accesso</p>
                                </Col>
                            </Row>
                            <Row >
                                <Col span="24">
                                    <Form layout='vertical'>
                                        <Form.Item label="Email"
                                            tooltip={{
                                                title: 'Inserisci l’indirizzo email che hai fornito al tuo gestore di filiale per l’apertura della richiesta di mutuo',
                                                icon: <img  data-type="button" src={info_grey} height="15" />
                                            }}>
                                            <Input type="email" name="mail" value={this.state.mail} placeholder="Inserisci il tuo indirizzo email"
                                                    onChange={e => this.setState({ mail: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label="Codice di accesso"
                                            tooltip={{
                                                title: 'Inserisci il codice di sicurezza che hai ricevuto tramite SMS. Se non hai a disposizione il codice di sicurezza puoi chiederne uno nuovo selezionando la voce “Richiedi un nuovo codice di accesso”',
                                                icon: <img  data-type="button" src={info_grey} height="15" />
                                            }}>
                                            <Input type="password" name="otp" value={this.state.password} placeholder="Codice di sicurezza"
                                                   onChange={e => this.setState({ password: e.target.value })} />
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                            <Row justify="end" >
                                <Col><a className="Semibold16Rosa" href="#" onClick={this.richiediPassword}>Richiedi un nuovo codice di accesso</a></Col>
                            </Row>
                            <br />
                            <br />
                            <Button type="primary" className="gradient-button Bold18Bianco" shape="round" block onClick={this.login}>PROCEDI</Button>
                         </div>
                    </Card>
                </Col>
                </Row>
             </div>
        )
    }
}


PageLoginCmp.contextType = AppContext;

export const PageLogin = PageLoginCmp;