import { Avatar, Card, Col, Modal, Row } from 'antd'
import * as React from 'react'
import { withRouter } from 'react-router'

import { IDocumentiService } from '../../services/DocumentiService'
import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { AllDocType, CategorieDocumentoResponseModel, TipoDocumento, TipoDocumentoModel } from '../../model/DocumentiModels'

import { NavigationBar } from '../NavigationBar'
import folders from './../../content/Icon-feather-folder.svg'
import info from './../../content/info_grey.svg'
import inoltrato from './../../content/inoltrato.svg'
import { AppContext } from '../AppContext'

class PageCategoriaDocumentiCmp extends React.Component<any, CategorieDocumentoResponseModel> {

    context!: React.ContextType<typeof AppContext>
    private documentiService: IDocumentiService

    constructor(props) {
        super(props)
        this.state = new CategorieDocumentoResponseModel()
        this.documentiService = IoCContainer.get(SERVICE_IDENTIFIER.DOCUMENTI_SERVICE)
    }

    async componentDidMount() {
        this.context.setLayoutVisibility(false, true, this.renderTitle());
        var res = await this.documentiService.GetCategorieDocumenti(this.props.match.params.id)
        if (res.IsValid === true)
            this.setState(res)
    }

    go = (tipoDocumento: TipoDocumento) => {
        this.props.history.push('/CaricaDoc/' + this.props.match.params.id + '/' + TipoDocumento[tipoDocumento])
    }

    onHelpClick = () => {
        Modal.info({
            width: 800,
            title: "Allega tutti i documenti richiesti ed inviali al tuo specialista mutui",
            content: <>Ti manderemo una notifica appena è disponibile l'esito della verifica.<br />
                Ti ricordiamo che la valutazione avverrà all'invio di tutti i documenti richiesti. Ecco alcuni consigli per rendere i tuoi documenti più chiari e leggibili:<br />
                <ul>
                    <li>Fai attenzione alle fotocopie troppo scure: i documenti devono essere leggibili in tutte le loro parti;</li>
                    <li>Non usare evidenziatori o pennarelli poiché possono rendere difficile la lettura dei documenti.</li>
                </ul>
            </>
        })
    }

    renderTitle:any = () => {
        return <span>Allega Documenti&nbsp;<a onClick={() => this.onHelpClick()}><img src={info} style={{ width: '18px' }} /></a></span>
    }

    renderStato = (props: TipoDocumentoModel) => {
        debugger
        if (props.State == null) return <Row><Col><span >Documenti caricati</span><span > {props.Count}/{props.Total}</span></Col></Row>;
        if (props.State == AllDocType.Inviati) return <Row><Col><span >In verifica </span><img src={inoltrato} style={{ width: '14px' }} /></Col></Row>;
        if (props.State == AllDocType.Verificato) return <Row><Col><span >Verificati </span></Col></Row>;

        return <Row><Col><span>Documenti caricati</span><span> {props.Count}/{props.Total}</span></Col></Row>;
    }

    renderCategoria = (props: TipoDocumentoModel) => {
        return (
            <Col key={props.Id} xl={7} md={10} xs={24}>
                <a onClick={() => this.go(props.Tipo)}>
                    <div className="timeline-card">
                        <Row>
                            <Col span="18">
                                <Row><span className="Bold24Rosso">{props.Description}</span></Row>
                                <Row className="Semibold16VerdeScuro">{this.renderStato(props)}</Row>
                            </Col>
                            <Col span="4"><Avatar src={folders } size={48} className="tile-card-avatar" /></Col>
                        </Row>

                        

                    </div>
                </a>
            </Col>
        )

    }

    renderCategorie = () => {
        if (this.state.Categorie == null) return
        return this.state.Categorie.map(this.renderCategoria)
    }

    render() {
        return (
            <>
                    <Row justify="center" gutter={[30, 30]}>
                        {this.renderCategorie()}
                    </Row>
            </>
        )
    }
}

PageCategoriaDocumentiCmp.contextType = AppContext;

export const PageCategoriaDocumenti = withRouter(PageCategoriaDocumentiCmp)
