import * as React from 'react'
import { Avatar, Button, Card, Col, Divider, Row } from 'antd'
import { RouteComponentProps } from 'react-router'

import { IPraticaService } from '../../services/PraticaService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PraticaRouteParams } from '../PraticaRouteParams'
import { ReferenteModel } from '../../model/PraticaModels'

import { NavigationBar } from '../NavigationBar'
import { CardSection } from '../CardSection'
import { ListItemHoriz, ListItemVert } from '../ListItem'

import profile from '../../content/consultant.svg'
import { AppContext } from '../AppContext'

class PageReferenteState extends ReferenteModel {

}

export class PageReferenteCmp extends React.Component<RouteComponentProps<PraticaRouteParams>, PageReferenteState> {

    service: IPraticaService
    context!: React.ContextType<typeof AppContext>
    constructor(props) {
        super(props)
        this.state = new PageReferenteState()
        this.service = container.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    componentDidMount() {
        this.context.setLayoutVisibility(false, true, "Specialista mutui");
        this.fetchData()
    }

    fetchData = async () => {
        const id = this.props.match.params.id
        const resp = await this.service.GetReferente(+id)
        if (resp.IsValid === true)
            this.setState(resp)
    }

    onChiamaClick = () => {
        window.open(`tel:${this.state.Telefono}`)
    }

    render() {
        return (
            <>
                <Row justify="center">
                    <Col xl={8} xs={24}>
                            <Row justify="space-around">
                                <Col xs={16} ><span className="Bold24Rosso">{this.state.Referente}</span></Col>
                                <Avatar src={profile} size={64} className="tile-card-avatar" />
                                
                            </Row>
                            <br />
                            <br />

                            <CardSection title="Contatto telefonico">
                            <ListItemHoriz title="Cellulare">{this.state.Telefono}</ListItemHoriz>
                            <ListItemHoriz title="Mail">{this.state.Mail}</ListItemHoriz>
                            </CardSection>

                            <br />
                            <br />
                            <Button type="primary" className="gradient-button Bold18Bianco" shape="round" block onClick={() => this.onChiamaClick()}>CHIAMA</Button>
                    </Col>
                </Row>
            </>
        )
    }
}

PageReferenteCmp.contextType = AppContext;

export const PageReferente = PageReferenteCmp;