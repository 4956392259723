import * as React from 'react'
import { Table, Button, Collapse, Upload, Result, Modal, Row, Col, Avatar, message} from 'antd'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import { RouteComponentProps, withRouter } from 'react-router'

import { IDocumentiService } from '../../services/DocumentiService'
import IoCContainer from '../../wires/Bootstrapper'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'

import { AllDocType, DocumentiInput, DocumentiResponseModel, DocumentiUploadInput, Documento, DocumentoDeleteInput, DocumentState, TipoDocumento } from '../../model/DocumentiModels'
import { CaricaDocumentiRouteParams } from './CaricaDocumentiRouteParams'

import successIcon from './../../content/icon_status.svg'
import infoIcon from './../../content/info_grey.svg'
import folders from './../../content/Icon-feather-folder.svg'
import upload from './../../content/upload.svg'
import trash from './../../content/trash.svg'

import './caricadocumenti.less'
import { AppContext } from '../AppContext'
import { RcFile } from 'antd/es/upload/interface'

const { Panel } = Collapse
const { Column } = Table

const iconInviatoStyle = {
    width: '40px', height: '40px'
}

export class GroupedItem {
    key: string
    value: Array<Documento>
}
const { confirm } = Modal;

class PageCaricaDocumentiCmp extends React.Component<RouteComponentProps<CaricaDocumentiRouteParams>, DocumentiResponseModel> {

    private documentiService: IDocumentiService
    private tipoDoc: TipoDocumento

    context!: React.ContextType<typeof AppContext>

    getStatoDescription = (stato: DocumentState): React.ReactNode => {
        switch (stato) {
            case DocumentState.Invalido: return <span className="Bold12VerdeScuro">Da Caricare</span>
            case DocumentState.Downloadabile: return <span className="Bold12VerdeScuro">Scaricabile</span>
            case DocumentState.DaCaricare: return <span className="Bold12VerdeScuro">Da Caricare</span>
            case DocumentState.Caricato: return <span className="Bold12VerdeScuro">Caricato</span>
            case DocumentState.DaVerificare: return <span className="Bold12VerdeScuro">In verifica</span>
            case DocumentState.Verificato: return <span className="Bold12Rosa">Verificato</span>
            default: return ""
        }
    }

    uploadImage = async (options: RcCustomRequestOptions, iddoc: string) => {
        const { file } = options

        var data = new DocumentiUploadInput()
        data.idDocumento = iddoc
        data.files = [file]
        data.idPratica = this.props.match.params.id
        data.tipodocumento = TipoDocumento[this.tipoDoc]
        var resp = await this.documentiService.UploadFiles(data)

        if (resp!= undefined && resp.IsValid === true)
            this.setState(resp)
    };

    beforeUpload = (file: RcFile, FileList: RcFile[]) => {
        if (FileList != undefined) {
            var size = 0;
            FileList.forEach(element => size += element.size);
            if (size > 20971520) {
                message.warning('Il limite di upload superato, la somma dei file supera i 20MB');
                return false;
            }
        }

        return true
    };

    deleteImage = async (iddoc: string) => {

        var data = new DocumentoDeleteInput()
        data.idDocumento = iddoc
        data.IdPratica = +this.props.match.params.id
        data.TipoDocumento = this.tipoDoc

        var resp = await this.documentiService.DeleteFile(data)
        if (resp.IsValid === true)
            this.setState(resp)
    };

    groupBy = (list, keyGetter) => {
        const map = new Map()
        list.forEach((item) => {
            const key = keyGetter(item)
            const collection = map.get(key)
            if (!collection) {
                map.set(key, [item])
            } else {
                collection.push(item)
            }
        })
        return map
    }

    inviaDoc = async () => {
        var data = new DocumentiInput()
        data.idPratica = +this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]


        confirm({
            title: 'Dichiarazione di Responsabilità',
            content: <>Dichiaro di essere autorizzato a caricare i documenti richiesti anche per conto degli altri interessati alla richiesta di mutuo.<br />
                     In assenza di autorizzazione la tua richiesta non potrà essere presa in carico</>,
            onOk: async ()=> {
                var resp = await this.documentiService.InviaDocumenti(data)
                if (resp.IsValid === true)
                    this.setState(resp)
            },
            onCancel() {
                console.log('Cancel');
            },
        });


        
    }


    constructor(props) {
        super(props)
        var initialstate = new DocumentiResponseModel();
        initialstate.IsLoaded = false;
        this.state = initialstate;

        this.documentiService = IoCContainer.get(SERVICE_IDENTIFIER.DOCUMENTI_SERVICE)
        this.tipoDoc = TipoDocumento[this.props.match.params.tipodocumento]
    }

    async componentDidMount() {
        let data = new DocumentiInput()
        data.idPratica = +this.props.match.params.id
        data.TipoDocumento = TipoDocumento[this.tipoDoc]

        this.context.setLayoutVisibility(false, true, `Allega Documenti > ${this.renderPageTitle()}`,false);

        var res = await this.documentiService.GetDocumenti(data)
        if (res.IsValid === true)
            this.setState({ ...res, IsLoaded: true });
    }

    renderDescrizione = (row: Documento) => {
        if (!row.Note) return <>
                                <span className="Regular14VerdeScuro">{row.Nome}</span>
                                <br/> 
            <span className="Regular11VerdeScuro" >{row.Descrizione}</span>
                              </>
        return <>
            <div>
                <span className="Regular14VerdeScuro">{row.Nome}</span>
                <br />
                <span className="Regular11VerdeScuro" >{row.Descrizione}</span>
            </div>
            <div style={{ borderLeft: '2px solid red', height: '100%', paddingLeft: '12px' }}>
                <span className="Regular14Grey700">{row.Note}</span>
            </div>
        </>
    }

    renderStatoAction = (row: Documento) => {
        switch (row.Stato) {
            case DocumentState.Invalido:
            case DocumentState.DaCaricare:
                return (
                    <Upload 
                        multiple
                        showUploadList={false}
                        customRequest={options => this.uploadImage(options, row.IdDocumento)}
                        beforeUpload={(file, files) =>  this.beforeUpload(file, files)}
                    >
                        <Avatar src={upload } size={28} className="tile-card-avatar" />
                    </Upload>
                )
            case DocumentState.Caricato:
                return (<span onClick={() => this.deleteImage(row.IdDocumento)}>
                    <Avatar src={trash} size={28} className="tile-card-avatar" />
                 </span>)
                /*return <Button type='primary' ghost shape='round' onClick={() => this.deleteImage(row.IdDocumento)}>Elimina</Button>*/
            default:
                return <></>
        }
    }


    renderdocs = (key: string, value: Array<Documento>) => {
        return (
            <Panel header={<span className='Bold24Rosa'>{key}</span>} key={key}>
                <Table dataSource={value} size="middle" rowKey={it => it.IdDocumento} pagination={false}>
                    <Column title={() => <span className="Bold14Rosso" >Tipo documento</span>} dataIndex="Descrizione" render={(_, row: Documento) => this.renderDescrizione(row)} />
                    <Column title={() => <span className="Bold14Rosso" >Stato</span>}  dataIndex="Stato" render={(_, row: Documento) => this.getStatoDescription(row.Stato)} />
                    <Column title="" dataIndex="Id" render={(_, row: Documento) => this.renderStatoAction(row) } />
                </Table>
            </Panel>
        )
    }

    renderPageTitle = () => {
        return this.tipoDoc == TipoDocumento.immobile ? 'Immobile' : 'Personali'
    }

    render() {
        const hasDocuments = this.state.Documenti != null && this.state.Documenti.length > 0
        if (this.state.IsLoaded == false) return <></>;
        return (
            <div>

                {(hasDocuments === true)
                    ? <div className="tt-documents-card" >
                        <Row align='middle'>
                            <Col xs={10}><span style={{ margin: '30px' }} className="Bold24White">{this.tipoDoc ==TipoDocumento.immobile ? 'Immobile' : 'Personali'}</span></Col>
                            <Col  xs={2} offset={10}><img src={folders}></img></Col>
                        </Row>
                        <Collapse bordered={true} accordion expandIconPosition='right' style={{ marginTop: '20px', paddingTop:'20px'}} >
                            {Array.from(this.groupBy(this.state.Documenti, doc => doc.CategoryTitle).keys()).map((k) =>
                                    this.renderdocs(k, this.groupBy(this.state.Documenti, doc => doc.CategoryTitle).get(k)))}
                    </Collapse></div>
                    : <Result
                        icon={<img src={infoIcon} style={iconInviatoStyle} />}
                        title={<span className='Medium18PlacidGreen'>Nessun documento necessario per questa sezione</span>} /> 
                }
                {(this.state.StatoDocumenti === AllDocType.DaCaricare || this.state.StatoDocumenti === AllDocType.Caricati)
                    ? <Button className="Bold18Bianco" type='primary' shape='round' disabled={this.state.StatoDocumenti != AllDocType.Caricati}
                        style={{ float: 'right', marginTop: '20px', width: '250px' }}
                        onClick={() => this.inviaDoc()}>INVIA</Button>
                    : <></>}
                {(this.state.StatoDocumenti === AllDocType.Inviati && hasDocuments === true)
                    ? <Result
                        icon={<img src={successIcon} style={iconInviatoStyle} />}
                        title={<span className='Medium18PlacidGreen'>Documenti inviati con successo!</span>}
                        subTitle={<span className="Regular14Grey800">Potrai consultare l'esito della verifica in questa pagina. Ti manderemo una notifica quando l'esito sarà disponibile</span>} />
                    : <></>}
            </div>
        )
    }
}
PageCaricaDocumentiCmp.contextType = AppContext;
export const PageCaricaDocumenti = withRouter(PageCaricaDocumentiCmp)
