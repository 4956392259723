import * as React from 'react';


export class AppContextModel {
    IsAppHeaderVisible: boolean
    IsAppFooterVisible: boolean
    hasPadding:boolean
    title: React.ReactNode
    setLayoutVisibility: (header: boolean, footer: boolean, title: string, hasPadding?: boolean) => {}
}

export const defaultContext: AppContextModel = {
    IsAppHeaderVisible: false,
    IsAppFooterVisible: false,
    title: '',
    hasPadding:true,
    setLayoutVisibility: null

}

export const AppContext = React.createContext<AppContextModel>(defaultContext);
