import * as React from 'react'
import { Layout, Menu, Col, Row, Card } from 'antd'

import SERVICE_IDENTIFIER from '../wires/Identifiers'
import container from '../wires/Bootstrapper'

import { IAuthService } from './../services/security/AuthServiceTs'

import logout from '../content/logout.svg'

import logo from '../content/AvveraLogo.svg';

export class AppHeader extends React.Component<any, any> {

    authService: IAuthService

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
    }

    onLogoutClick = () => {
        this.authService.signOutAndReload()
    }

    render() {
        return (

            <Card className='card-top' >
                <Row justify="space-between" align="middle">
                    <Col></Col>
                    <Col><img src={logo} style={{ height: '56px' }} /></Col>
                    <Col><a onClick={() => this.onLogoutClick()}><img src={logout} /></a></Col>
                </Row>
            </Card>
        )
    }
}