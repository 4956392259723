import * as React from 'react'
import { Card, Col, Collapse, Row } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router'

import { IPraticaService } from '../../services/PraticaService'
import SERVICE_IDENTIFIER from '../../wires/Identifiers'
import container from '../../wires/Bootstrapper'

import { PraticaRouteParams } from '../PraticaRouteParams'
import { PraticaMutuoTimelineItemModel } from '../../model/PraticaModels'
import { NavigationBar } from '../NavigationBar'
import { TimelineCard } from './TimelineCard'
import { AppContext } from '../AppContext'

class PageTimelineState {
    Timeline: Array<PraticaMutuoTimelineItemModel>
    ExpandedKeys: Array<string> = []
}

class PageTimelineComp extends React.Component<RouteComponentProps<PraticaRouteParams>, PageTimelineState> {

    service: IPraticaService
    context!: React.ContextType<typeof AppContext>
    constructor(props) {
        super(props)
        this.state = new PageTimelineState()
        this.service = container.get(SERVICE_IDENTIFIER.PRATICA_SERVICE)
    }

    componentDidMount() {
        this.context.setLayoutVisibility(false, true, "Stato della richiesta");

        this.fetchData()
    }

    fetchData = async () => {
        const id = this.props.match.params.id
        const resp = await this.service.GetPraticaTimeline(+id)
        if (resp.IsValid === true) {
            const keys = this.calcDefaultExpanded(resp.Timeline)
            this.setState({ Timeline: resp.Timeline, ExpandedKeys: keys })
        }
    }

    calcDefaultExpanded = (list: Array<PraticaMutuoTimelineItemModel>): Array<string> => {
        let keys: Array<string> = []
        const idx = list.findIndex(e => e.StatoCorrente === true)
        if (idx < 0)
            return keys

        keys.push(list[idx].Codice)
        if (idx > 0)
            keys.push(list[idx - 1].Codice)

        return keys
    }

    renderTimeline = () => {
        if (!this.state.Timeline) return
        return this.state.Timeline.map(item => <TimelineCard key={item.Codice} item={item} defaultExpandedNodes={this.state.ExpandedKeys} />)
    }

    render() {
        return (
            <>
                <Row justify="center">
                    <Col xl={16} xs={24}>
                        {this.renderTimeline()}
                    </Col>
                    </Row>
            </>
        )
    }
}

PageTimelineComp.contextType = AppContext;

export const PageTimeline = withRouter(PageTimelineComp)