import * as React from 'react'
import { Divider } from 'antd'

interface CardSectionProps {
    title: string
}

export const CardSection: React.FC<CardSectionProps> = (props) => {
    return (
        <>
            <span className="Bold24Rosa" style={{ margin: '0 12px' }}>{props.title}</span>
            <Divider style={{ margin: '12px 0' }} />
            <div style={{ padding: '0 12px' }}>
                {props.children}
            </div>
        </>
    )
}