import * as React from 'react'
import { Col, Row } from 'antd'
import { formatEuro } from '../shared/CurrencyFormat'

import info_white from '../../content/info_white.svg'

import './HeaderCard.less'

interface HeaderCardProps {
    id: string
    importo: number
    durata: string
    tasso: string
    onDettaglioClick: (id: string) => void
}

export const HeaderCard: React.FC<HeaderCardProps> = (props) => {
    return (
        <div > {/* <div style={{ height: '280px' }}> Il primo div è necessario per visualizzare l'indicatore del carosello */}
            <div className="header-card" style={{ margin: '24px' }}>
                <Row align="middle">
                    <Col offset={1} lg={2} xs={4}>
                        <a onClick={() => props.onDettaglioClick(props.id)}><img src={info_white} /></a>
                    </Col>
                    <Col lg={21} xs={19}>
                        <span className="Bold18Bianco">La mia richiesta di mutuo</span>
                    </Col>
                </Row>
                <div className="header-card header-inner-card">
                    <Row justify="center">
                        <Col><span className="RegularUnica62VerdesScuro">{formatEuro(props.importo)}</span></Col>
                    </Row>
                    <Row justify="center">
                        <Col><span className="Semibold17VerdeScuro">DURATA:&nbsp;</span></Col>
                        <Col><span className="RegularUnica17Rosa">{props.durata}</span></Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}