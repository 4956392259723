import * as React from 'react'
import { Avatar, Badge, Card, Col, Row } from 'antd'

import badgeIcon from '../../content/ic_badge_notifica.png'
import "./TileCard.less"

interface TileCardProps {
    onClick: () => void
    icon: React.ReactNode
    highlighted: boolean
}

export const TileCard: React.FC<TileCardProps> = (props) => {
    return <a onClick={() => props.onClick()}>
        {/*<Badge count={<img src={badgeIcon} className="tile-card-badge" />}>*/}
        <Card className='tt-card-tile' >
            {props.highlighted ? <img src={badgeIcon} className="tile-card-badge" /> : <></>}
            <Row justify="center" align="middle" className="tile-card-row">
                <Col xs={8}>
                    <Avatar src={props.icon} size={40} className="tile-card-avatar" />
                </Col>
                <Col xs={14}>
                    <span className="Bold20Nero">{props.children}</span>
                </Col>
            </Row>
            </Card>
        {/*</Badge>*/}
    </a>
}