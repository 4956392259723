import * as React from 'react'
import { Col, Layout, Row } from 'antd'
import { Route } from 'react-router'

import moment from 'moment'
import 'moment/locale/it'

import { IAuthService } from './services/security/AuthServiceTs'

import container from './wires/Bootstrapper'
import SERVICE_IDENTIFIER from './wires/Identifiers'

//import { AppContext } from './components/AppContext'
import { AppHeader } from './components/AppHeader'
import { AppRoutes } from './components/AppRoutes'

import { PageLogin } from './components/login/PageLogin'
import { AppContext, AppContextModel, defaultContext } from './components/AppContext'
import { AppFooter } from './components/AppFooter'
import { NavigationBar } from './components/NavigationBar'

const { Content, Footer } = Layout


moment.locale('it')

export default class App extends React.Component<any, AppContextModel> {
    //static displayName = App.name;

    authService: IAuthService

    constructor(props) {
        super(props)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)

        this.state = {
            IsAppHeaderVisible: defaultContext.IsAppHeaderVisible,
            IsAppFooterVisible: defaultContext.IsAppFooterVisible,
            setLayoutVisibility: this.setLayoutVisibility,
            hasPadding:defaultContext.hasPadding,
            title: defaultContext.title
        }
    }


    renderUnauthenthicated = () => {
        return (
            <Layout >
                <Content>
                    <Route path="/" component={() => <PageLogin />} />
                </Content>
            </Layout>
        )
    }

    setLayoutVisibility = async (header: boolean, footer: boolean, title:React.ReactNode, hasPadding:boolean = true) => {
        debugger;
        this.setState({ IsAppHeaderVisible: header, IsAppFooterVisible: footer, title: title, hasPadding: hasPadding})
    }

    renderAuthenticated = () => {
        return (
            <Layout className="full-height">
                <Layout>
                    <AppContext.Provider value={this.state}>
                        {this.state.IsAppHeaderVisible ? <AppHeader /> : <NavigationBar title={ this.state.title} />}
                        <Layout className="tt-layout">
                            <Content className={`tt-content ${this.state.hasPadding ? '' : 'tt-content-nopadding'}`}>
                                <Row>
                                    <Col lg={{ offset: 4, span: 16 }}  xs={{  span: 24 }} >
                                        <AppRoutes />
                                    </Col>
                                </Row>
                            </Content>
                            <Footer  >
                                {this.state.IsAppFooterVisible ? <AppFooter /> :<></>}
                            </Footer>
                        </Layout>
                    </AppContext.Provider >
                </Layout>
            </Layout>
        )
    }

    render() {
        return this.authService.isAuthenticated() == false
            ? this.renderUnauthenthicated()
            : this.renderAuthenticated()
    }
}
