import * as React from 'react'
import { Layout, Menu, Col, Row, Card } from 'antd'


import logo from '../content/AvveraLogo.svg';

export const AppFooter: React.FC = (props) => {
    return (
        <img src={logo} className="tt-footer" />
    )
}
