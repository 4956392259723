
export interface ILoginModel
{
    UserName: string;
    PassWord: string;
    Token: string;
}

export class LoginModel implements ILoginModel
{
    UserName: string;    
    PassWord: string; 
    Token: string; 
}
